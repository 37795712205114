
import router from "@/router";
import {
  defineComponent,
  ref,
  watch,
  toRefs,
  reactive,
  onMounted,
  onUpdated,
  inject,
} from "vue";
import UserData from "@/store/user";
import { useRouter } from "vue-router";
import { loginOut } from "@/api/login";
import { getMsg, getBidingBidVoList } from "@/api/test";
import { ElMessage, ElMessageBox } from "element-plus";
import { ElNotification } from "element-plus";
export default defineComponent({
  name: "userNavHtml",
  components: {},
  setup(props) {
    const UserDataStore = UserData();
    const Router = useRouter();
    const activeIndex = ref(0);
    const data: any = reactive({
      tableData: [],
      acActive: 0,
      down: "", //竞价室倒计时
      router_path: "", //当前路由
      ws_url: "",
    });
    const emitter: any = inject("emitter"); // Inject `emitter`
    emitter.on("leavePage2", (value: any) => {
      // 监听事件
      console.log(value);
      data.down = value;
      // TODO do something
    });
    const routers = Router.options.routes.filter((item, index) => {
      return item?.meta?.isShowNav;
    });
    const navs = routers.map((item) => {
      return {
        title: item?.meta?.title,
        path: item?.path,
      };
    });

    const setActiveIndex = (index: any) => {
      activeIndex.value = index;
    };
    router.afterEach((to) => {
      routers.filter((item, index) => {
        if (to.path === item.path) {
          activeIndex.value = index;
        }
      });
    });
    //向竞价室发送事件
    const leavePage = () => {
      emitter.emit("leavePage", "leavePage");
    };
    //跳转登录注册
    const routePush = (res: number | string, name: any) => {
      leavePage();
      if (router.currentRoute.value.path == "/jingjiaing" && data.down != 0) {
        ElMessageBox.confirm(
          "您当前正在参与竞价中，离开可能无法及时浏览最新出价，是否确定离开~",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then((result) => {
          router.push({
            path: `${res}`,
            query: {
              name: name,
            },
          });
        });
        return;
      }
      router.push({
        path: `${res}`,
        query: {
          name: name,
        },
      });
    };
    const toPath = (item: any) => {
      leavePage();
      if (router.currentRoute.value.path == "/jingjiaing" && data.down != 0) {
        ElMessageBox.confirm(
          "您当前正在参与竞价中，离开可能无法及时浏览最新出价，是否确定离开~",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then((result) => {
          router.push(item.path);
        });
        return;
      }
      router.push(item.path);
    };

    const handleLogout = () => {
      loginOut({}).then((res: any) => {
        if (res.code === 200) {
          UserDataStore.SET_USER({});
          UserDataStore.SET_Login(false);
          router.push("/");
        }
      });
    };

    const goUserInfo = () => {
      router.push({
        path: "/info",
      });
    };
    // 监听是否登录事件
    const loginType = reactive({
      isLogin: UserDataStore.$state.isLogin,
      user: UserDataStore.$state.user,
    });
    watch(
      () => UserDataStore,
      (a) => {
        loginType.isLogin = a.$state.isLogin;
        loginType.user = a.$state.user;
        if (loginType.isLogin) {
          initSocket();
        }
      },
      { deep: true }
    );
    const messageData: any = reactive({
      msgList: [],
      toDoList: [],
      messageNum: 0,
      toDoNum: 0,
      msgType: 0,
      newTodoId: null,
    });
    const handlerTabs = (index: any) => {
      console.log(messageData.msgType);
      messageData.msgType = index;
    };
    const routclickss = (item: any) => {
      router.push({
        path: "/CompetitionView",
        query: {
          bidIds: item,
        },
      });
    };
    let newId: any = null;
    let getCount = 0;

    const initSocket = () => {
      let custId = "c-" + UserDataStore.$state.user.custId;
      if (process.env.VUE_APP_WSURL) {
        data.ws_url = process.env.VUE_APP_WSURL + custId + "";
      } else {
        // data.ws_url = "ws://45.119.63.165:8080/ws/websocket/"  + custId + "";
        data.ws_url = "ws://172.30.203.23:9205/dev-api/ws/websocket/"  + custId + "";

      }
      let ws = new WebSocket(data.ws_url);
      ws.onopen = function () {
        console.log("webSocket已打开");
        getListMsg();
      };
      ws.onmessage = (res: any) => {
        console.log(res, "webSocket推送消息");
        handleWebSocketMsg(res.data);
      };
      ws.onclose = (e) => {
        console.log("webSocket已断开", e);
        initSocket();
      };
    };
    const handleWebSocketMsg = (res: any) => {
      const data = JSON.parse(res);
      if (data.message == "update") {
        console.log("去更新数据");
        setTimeout(()=>{
          getListMsg();
        },1000)
        
      }
    };
    const getListMsg = () => {
      getMsg().then((res: any) => {
        if (res && res.data) {
          let data = res.data;
          messageData.msgNum = data.countMessage ? data.countMessage : "";
          messageData.toDoNum = data.countSchedule ? data.countSchedule : "";
          messageData.msgList =
            data.messageList.length > 0 ? data.messageList : [];
          messageData.toDoList =
            data.scheduleList.length > 0 ? data.scheduleList : [];
          if (data.scheduleList.length > 0) {
            newId = res.data.scheduleList[0].scheduleConnectedId;
          }

          if (getCount == 0 && messageData.toDoList.length > 0) {
            let msg = `您的标的【${messageData.toDoList[0].scheduleName}】待${messageData.toDoList[0].scheduleTypeName}`;
            sendMsg("您有新的待办事项待处理", msg);
          } else {
            if (
              data.scheduleList.length > 0 &&
              res.data.scheduleList[0].scheduleConnectedId != newId
            ) {
              let msg = `您的标的【${messageData.toDoList[0].scheduleName}】待${messageData.toDoList[0].scheduleTypeName}`;
              sendMsg("您有新的待办事项待处理", msg);
            }
          }
          getCount++;
        }
      });
    };
    const goPage = (url: any) => {
      router.push({
        path: url,
      });
    };
    //getListMsg()
    const sendMsg = (title: any, msg: any) => {
      ElNotification({
        title: title,
        message: msg,
        type: "warning",
        position: "bottom-right",
      });
    };

    //有正在竞价的标的跳转
    const getBidingBidVoListFN = () => {
      getBidingBidVoList().then((res) => {
        if (res.code == 200) {
          if (res.data.length) {
            // alert(router.currentRoute.value.path)
            // console.log(data.router_path)
            if (data.router_path == "/jingjiaing") {
              return;
            }
            for (let i = 0; i < res.data.length; i++) {
              console.log(res.data);
              if (res.data.length > 1) {
                ElMessageBox.confirm(`您已经报名的多个标的正在竞价中；`, {
                  cancelButtonText: "取消",
                  confirmButtonText: "快速参与",
                  type: "warning",
                  center: true,
                }).then(() => {
                  router.push({
                    path: "/biddingroom",
                  });
                });
                return;
              }
              ElMessageBox.confirm(
                `您已经报名的(${res.data[i].bidName})正在竞价中；`,
                {
                  cancelButtonText: "取消",
                  confirmButtonText: "快速参与",
                  type: "warning",
                  center: true,
                }
              ).then(() => {
                router.push({
                  path: "/jingjiaing",
                  query: {
                    id: res.data[i].bidIds,
                  },
                });
              });
            }
          }
        }
      });
    };

    onMounted(() => {
      if (loginType.isLogin) {
        initSocket();
        setTimeout(() => {
          data.router_path = router.currentRoute.value.path;
          console.log(data.router_path);
          getBidingBidVoListFN();
        }, 1000);
      }
    });
    // onUpdated(() => {
    //   if (loginType.isLogin) {
    //     initSocket();
    //   }
    // });

    return {
      handleLogout,
      goUserInfo,
      handlerTabs,
      toPath,
      routePush,
      routclickss,
      navs,
      UserDataStore,
      messageData,
      setActiveIndex,
      goPage,
      leavePage,
      getBidingBidVoListFN,
      ...toRefs(data),
      ...toRefs(loginType),
      activeIndex,
      initSocket,
    };
  },

  data() {
    return {
      queryInfo: {
        query: "",
      },
    };
  },
});
