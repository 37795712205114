import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const SignupView = () => import('@/views/SignupView.vue')
const BiddingHall = () => import('@/views/BiddingHall.vue')
const CompetitionView = () => import('@/views/CompetitionView.vue')
const appointView = () => import('@/views/appointView.vue')
// const appointView1 = () => import('@/views/appointView1.vue')
const LeaseView = () => import('@/views/LeaseView.vue')
const bidsPrintView = () => import('@/views/Mydetails/bidsPrintView.vue')
const ConsultationhelpView =()=> import('@/views/Mydetails/Consultationhelp.vue')
import { close, start } from '@/common/js/np'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '首页',
      isShowNav: true,//是否显示在导航栏
    }
  },
  {
    path: '/CompetitionView',
    name: 'CompetitionView',
    component: CompetitionView,
    meta: {
      title: '我要竞租',
      isShowNav: true,//是否显示在导航栏
    }
  },

  {
    path: '/login',
    name: 'login',
    component: LeaseView,
    meta: {
      title: '我要出租',
      isShowNav: false,//是否显示在导航栏
    }
  },
  {
    path: '/bidsPrint',
    name: 'bidsPrint',
    component: bidsPrintView,
    meta: {
      title: '我要打印',
      isShowNav: false,//是否显示在导航栏
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupView,
    meta: {
      title: '公告公示',
      isShowNav: true,//是否显示在导航栏
    }
  },

  {
    path: '/biddingHall',
    name: 'biddingHall',
    component: BiddingHall,
    meta: {
      title: '竞价大厅',
      isShowNav: true,//是否显示在导航栏
    }
  },
  {
    path: '/consultationhelp',
    name: 'consultationhelp',
    component: ConsultationhelpView,
    meta: {
      title: '咨询帮助',
      isShowNav: true,//是否显示在导航栏
    }
  },


  {
    path: '/appoint',
    name: 'appoint',
    component: appointView,
    meta: {
      title: '招租约定',
      isShowNav: true,//是否显示在导航栏
    }
  },
  { // 详情页面
    path: '/Mydetails',
    name: 'Mydetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mydetails/MydetailsView.vue'),
    meta: {
      title: '我的详情',
      isShowNav: false,//是否显示在导航栏
    }
  },

  { // 我要报名
    path: '/mysignup',
    name: 'mysignup',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mydetails/mysignup.vue'),
    meta: {
      title: '我的详情',
      isShowNav: false,//是否显示在导航栏
    }
  },
  // { // 我要报价
  //   path: '/myoffer',
  //   name: 'myoffer',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Mydetails/myoffer.vue'),
  //   meta: {
  //     title: '我的详情',
  //     isShowNav: false,//是否显示在导航栏
  //   }
  // },
  // { // 签中标函
  //   path: '/myLetterSigning',
  //   name: 'myLetterSigning',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Mydetails/myLetterSigning.vue'),
  //   meta: {
  //     title: '我的详情',
  //     isShowNav: false,//是否显示在导航栏
  //   }
  // },
  { // 时事新闻
    path: '/mycurrentaffairs',
    name: 'mycurrentaffairs',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mydetails/mycurrentaffairs.vue'),
    meta: {
      title: '时事更多',
      isShowNav: false,//是否显示在导航栏
    }
  },
  { // 时事新闻详情
    path: '/Newsdetail',
    name: 'Newsdetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mydetails/Newsdetail.vue'),
    meta: {
      title: '新闻详情',
      isShowNav: false,//是否显示在导航栏
    }
  },

   {// 平台通知
    path: '/Holidaynotice',
    name: 'Holidaynotice',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mydetails/Holidaynotice.vue'),
    meta: {
      title: '放假通知',
      isShowNav: false,//是否显示在导航栏
    }
  },
  {// 平台通知详情
    path: '/NoticeDetails',
    name: 'NoticeDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mydetails/NoticeDetails.vue'),
    meta: {
      title: '放假通知',
      isShowNav: false,//是否显示在导航栏
    }
  },
  {// 成交公告详情
    path: '/Transactiondetails',
    name: 'Transactiondetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mydetails/Transactiondetails.vue'),
    meta: {
      title: '放假通知',
      isShowNav: false,//是否显示在导航栏
    }
  },
   {//商机无限
    path: '/businessopportunity',
    name: 'businessopportunity',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mydetails/businessopportunity.vue'),
    meta: {
      title: '放假通知',
      isShowNav: false,//是否显示在导航栏
    }
  },

 { //商机无限详细通知
    path: '/PlatformNoti',
    name: 'PlatformNoti',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mydetails/PlatformNoti.vue'),
    meta: {
      title: '平台通知',
      isShowNav: false,//是否显示在导航栏
    }
  },

   {//报名确定
    path: '/Rsgcon',
    name: 'Rsgcon',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mydetails/Rsgcon.vue'),
    meta: {
      title: '放假通知',
      isShowNav: false,//是否显示在导航栏
    }
  },

  // {
  //   path: '/signup',
  //   name: 'signup',
  //   component: SignupView,
  //   meta: {
  //     title: '咨询帮助',
  //     isShowNav: true,//是否显示在导航栏
  //   }
  // },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: '关于我们',
      isShowNav: true,//是否显示在导航栏
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),
    meta: {
      title: '登录',
      isShowNav: false,//是否显示在导航栏
    }
  },
  //盖章
  {
    path: '/seal',
    name: 'seal',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mydetails/seal.vue'),
    meta: {
      title: '登录',
      isShowNav: false,//是否显示在导航栏
    }
  },
  //新盖章
  {
    path: '/sealNew',
    name: 'sealNew',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mydetails/sealNew.vue'),
    meta: {
      title: '登录',
      isShowNav: false,//是否显示在导航栏
    }
  },


    //个人主页板块
  //个人主页板块
  //个人主页板块

  {
    path: '/myhome',
    name: 'myhome',
    component: () => import(/* webpackChunkName: "about" */ '../views/myhome.vue'),
    meta: {
      title: '我的主页',
      isShowNav: false,//是否显示在导航栏
      keepAlive: false
    },
    children: [
			{
			  path: '/toDo',
			  name: 'toDo',
			  component: () => import('../views/myinfo/toDo.vue'),//根据你的菜单结构引入
			},
      {
        path: '/myMsg',
        name: 'myMsg',
        component: () => import('../views/myinfo/myMsg.vue'),//根据你的菜单结构引入
      },
      {
        path: '/myCollect',
        name: 'myCollect',
        component: () => import('../views/myinfo/myCollect.vue'),//根据你的菜单结构引入
      },
      {
        path: '/orderNo',
        name: 'orderNo',
        component: () => import('../views/myinfo/orderNo.vue'),//根据你的菜单结构引入
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('../views/myinfo/order.vue'),//根据你的菜单结构引入
      },
      {
        path: '/myMoney',
        name: 'myMoney',
        component: () => import('../views/myinfo/myMoney.vue'),//根据你的菜单结构引入
      },
      {
        path: '/myNatural',
        name: 'myNatural',
        component: () => import('../views/myinfo/myNatural.vue'),//根据你的菜单结构引入
      },
      {
        path: '/empower',
        name: 'empower',
        component: () => import('../views/myinfo/empower.vue'),//根据你的菜单结构引入
      },
      {
        path: '/passwordSet',
        name: 'passwordSet',
        component: () => import('../views/myinfo/passwordSet.vue'),//根据你的菜单结构引入
      },
      {
        path: '/phoneSet',
        name: 'phoneSet',
        component: () => import('../views/myinfo/phoneSet.vue'),//根据你的菜单结构引入
      },
      {
        path: '/myUser',
        name: 'myUser',
        component: () => import('../views/myinfo/myUser.vue'),//根据你的菜单结构引入
      },
      {
        path: '/heimingdan',
        name: 'heimingdan',
        component: () => import('../views/myinfo/heimingdan.vue'),//根据你的菜单结构引入
      },
      {
        path: '/myPageHome',
        name: 'myPageHomer',
        component: () => import('../views/myinfo/myPageHome.vue'),//我的主页
      },
      //我要出租
      {
        path: '/myhire',
        name: 'myhire',
        meta: {
          keepAlive: false
        },
        component: () => import('../views/myhire/myhire.vue'),//我的出租
      },
      {
        path: '/addAsset',
        name: 'addAsset',
        component: () => import('../views/myhire/addAsset.vue'),//新增资产
      },
      //委托资产
      {
        path: '/addAsset2',
        name: 'addAsset2',
        component: () => import('../views/myhire/addAsset2.vue'),//新增资产
      },
      {
        path: '/addAsset3',
        name: 'addAsset3',
        component: () => import('../views/myhire/addAsset3.vue'),//新增资产
      },
      {
        path: '/addAsset4',
        name: 'addAsset4',
        component: () => import('../views/myhire/addAsset4.vue'),//新增资产
      },
      {
        path: '/addAssetCF',
        name: 'addAssetCF',
        component: () => import('../views/myhire/addAssetCF.vue'),//重新提交
      },
			{
			  path: '/addAssetGb',
			  name: 'addAssetGb',
			  component: () => import('../views/myhire/addAssetGb.vue'),//关闭后进行编辑
			},
      {
        path: '/managementAsset',
        name: 'managementAsset',
        component: () => import('../views/myhire/managementAsset.vue'),//资产管理
      },
      {
        path: '/application',
        name: 'application',
        component: () => import('../views/myhire/application.vue'),//托管申请
      },
      {
        path: '/confirmation',
        name: 'confirmation',
        component: () => import('../views/myhire/confirmation.vue'),//标的确认
      },
      {
        path: '/duringanswer_hire',
        name: 'duringanswer_hire',
        component: () => import('../views/myhire/duringanswer_hire.vue'),//公示期答疑
      },
      {
        path: '/projectanswer',
        name: 'projectanswer',
        component: () => import('../views/myhire/projectanswer.vue'),//项目竞标前答疑
      },
      {
        path: '/letterConfirm',
        name: 'letterConfirm',
        component: () => import('../views/myhire/letterConfirm.vue'),//中标函确认
      },
      {
        path: '/contractConfirm',
        name: 'contractConfirm',
        component: () => import('../views/myhire/contractConfirm.vue'),//合同确认
      },
      {
        path: '/projectChange',
        name: 'projectChange',
        component: () => import('../views/myhire/projectChange.vue'),//标的变更申请
      },
      //我要租赁
      {
        path: '/mylease',
        name: 'mylease',
        component: () => import('../views/mylease/mylease.vue'),//我的租赁
      },
      {
        path: '/biddingroom',
        name: 'biddingroom',
        component: () => import('../views/mylease/biddingroom.vue'),//竞价室
      },
      {
        path: '/projectqs',
        name: 'projectqs',
        component: () => import('../views/mylease/projectqs.vue'),//项目竞标前提问
      },
      {
        path: '/duringanswer',
        name: 'duringanswer',
        component: () => import('../views/mylease/duringanswer.vue'),//公示期质疑
      },
      {
        path: '/projectissues',
        name: 'projectissues',
        component: () => import('../views/mylease/projectissues.vue'),//中标函确认
      },
      {
        path: '/biddingsignup',
        name: 'biddingsignup',
        component: () => import('../views/mylease/biddingsignup.vue'),//竞价报名
      },
      {
        path: '/trybidding',
        name: 'trybidding',
        component: () => import('../views/mylease/trybidding.vue'),//拭竞价
      },
      {
        path: '/jingjiaing',
        name: 'jingjiaing',
        component: () => import('../views/mylease/jingjiaing.vue'),//真竞价室
      },
      {
        path: '/jingjiaingz',
        name: 'jingjiaingz',
        component: () => import('../views/mylease/jingjiaingz.vue'),//拭竞价 正
      },
      {
        path: '/jingjiaingf',
        name: 'jingjiaingf',
        component: () => import('../views/mylease/jingjiaingf.vue'),//拭竞价 反
      },
      {
        path: '/upbond',
        name: 'upbond',
        component: () => import('../views/mylease/upbond.vue'),//提交服务费
      },
      {
        path: '/upmoney',
        name: 'upmoney',
        component: () => import('../views/mylease/upmoney.vue'),//提交保证金
      },
      {
        path: '/retreatmoney',
        name: 'retreatmoney',
        component: () => import('../views/mylease/retreatmoney.vue'),//退还保证金
      },
      {
        path: '/fapiao',
        name: 'fapiao',
        component: () => import('../views/mylease/fapiao.vue'),//申请发票
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.onError((error,to, from) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = to.fullPath;
  console.log(error)
  //router.replace(to.fullPath);
   window.location.href=targetPath;
   //window.location.reload();
});
router.beforeEach((to: any, from, next) => {
  /* 路由发生变化修改页面title */
  // if (to?.meta?.title) {
  //   document.title = to.meta.title;
  // }
  start()
  next();
})
router.afterEach(() => {
  setTimeout(()=>{
    close()
  },1000)
})


export default router
