<template>
  <div class="containerss shadow" v-if="ishow">
    <div class="main">
      <div class="Intelligent">
        <div class="head">
          <div class="head-service">
            智能小助理
            <span v-show="inputing" class="inputing">{{inputing}}</span>
          </div>
          <div class="head-button">
            <div class="shouzhi" @click="ishow=false;">
              <el-icon size="40" color="#fff">
                <CloseBold />
              </el-icon>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="chat">
            <div class="chat-frame">
              <div id="content" class="content">
                <div v-for="(item, index) in info" :key="index">
                  <div class="info_r info_default" v-if="item.type == 'leftinfo'">
                    <span class="circle circle_r"></span>
                    <div class="con_r con_text">
                      <div>{{ item.content }}</div>
                      <div v-for="(item2, index) in item.question" :key="index">
                        <div class="con_que" @click="clickRobot(item2.content, item2.id)">
                          <div class="czkj-question-msg">{{ item2.index }} {{ item2.content }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="time_r">{{ item.time }}</div>
                  </div>

                  <div class="info_l" v-if="item.type == 'rightinfo'">
                    <div class="con_r con_text">
                      <div class="con_l">{{ item.content }}</div>
                      <span class="circle circle_l">
                        <img src class="pic_l" />
                      </span>
                    </div>
                    <div class="time_l">{{ item.time }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="chat-input">
              <div class="setproblem">
                <textarea
                  placeholder="请输入您的问题..."
                  style="
                    height: 100%;
                    width: 100%;
                    resize: none;
                    padding-right: 100px;
                    outline: none;
                    border-color: #ccc;
                    border-radius: 5px;
                  "
                  id="text"
                  v-model="customerText"
                  @keyup.enter="sentMsg()"
                ></textarea>
                <button @click="sentMsg()" class="setproblems">
                  <span style="vertical-align: 4px">发 送</span>
                </button>
              </div>
            </div>
            <div class="chat-introduce">智能客服软件</div>
          </div>
          <div class="message">
            <div class="portrait">
              <img src="@/views/images/logos.png" />
            </div>
            <div class="phone">
              <el-icon>
                <Iphone />
              </el-icon>电话： 0591-83726089
            </div>
            <!-- <div class="site">
              <el-icon>
                <Location />
              </el-icon>地址：福州市台江区鸡笼洲路2号福州城投榕发大厦4楼
            </div> -->
            <div class="url">
              <el-icon>
                <Monitor />
              </el-icon>网址：https://sihaiyilian.com/
            </div>
            <div class="lfx">
              <img src="@/assets/image/erweima.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { inject } from "vue";
import { customerReply } from "@/api/myinfo"
import { ElMessage } from "element-plus";
import router from "@/router";
export default {
  name: "onlineCustomer",
  components: {},
  computed: {},
  data() {
    return {
      ishow: false,
      customerText: "",
      info: [],
      timer: null,
      robotQuestion: [],
      robotAnswer: [],
      inputing:"",//正在输入中...
    };
  },
  watch: {},
  methods: {
    // 用户发送消息
    sentMsg() {
      this.inputing = "正在输入中...";
      clearTimeout(this.timer);
      let text = this.customerText.trim();
      if (text != "") {
        this.sendMsgs();
        var obj = {
          type: "rightinfo",
          time: this.getTodayTime(),
          content: text
        };
        this.info.push(obj);
        this.appendRobotMsg(this.customerText);
        this.customerText = "";
        this.$nextTick(() => {
          var contentHeight = document.getElementById("content");
          contentHeight.scrollTop = contentHeight.scrollHeight;
        });
      }else{
        ElMessage.warning("输入不能为空！");
      }
    },
    // 发送信息
    sendMsgs(){
      debugger
      let params = {
        keyword:this.customerText.trim()
      }
      customerReply(params).then(res=>{
        if(res.code == 200){
          if(res && res.data){
            this.info.push({
              id: res.data.callcenterIds,
              content:res.data.wordAnswer,
              index: res.data.callcenterIds,
              type: "leftinfo",
              time: this.getTodayTime(),
              name: "robot",
            });
          }
        }
        else {
          if(res && res.code == 401) {
            ElMessage.error('请先登录');
            router.push("/login?name=login");
          }
          else if(res.msg){
            ElMessage.error(res.msg);
          }
        }

        this.$nextTick(() => {
          var contentHeight = document.getElementById("content");
          contentHeight.scrollTop = contentHeight.scrollHeight;
        });
      }).finally(()=>{
        debugger
        this.inputing = "";
      })
    },


    // 机器人回答消息
    appendRobotMsg(text) {
      clearTimeout(this.timer);
      text = text.trim();
      let answerText = "";
      let flag;
      for (let i = 0; i < this.robotAnswer.length; i++) {
        if (this.robotAnswer[i].content.indexOf(text) != -1) {
          flag = true;
          answerText = this.robotAnswer[i].content;
          break;
        }
      }
      if (flag) {
        let obj = {
          type: "leftinfo",
          time: this.getTodayTime(),
          name: "robot",
          content: answerText,
          question: []
        };
        this.info.push(obj);
      } 
      // else {
      //   answerText = "您可能想问：";
      //   let obj = {
      //     type: "leftinfo",
      //     time: this.getTodayTime(),
      //     name: "robot",
      //     content: answerText,
      //     question: this.robotQuestion
      //   };
      //   this.info.push(obj);
      // }
      this.$nextTick(() => {
        var contentHeight = document.getElementById("content");
        contentHeight.scrollTop = contentHeight.scrollHeight;
      });
    },
    sentMsgById(val, id) {
      clearTimeout(this.timer);
      let robotById = this.robotAnswer.filter(item => {
        return item.id == id;
      });
      let obj_l = {
        type: "leftinfo",
        time: this.getTodayTime(),
        name: "robot",
        content: robotById[0].content,
        question: []
      };
      let obj_r = {
        type: "rightinfo",
        time: this.getTodayTime(),
        name: "robot",
        content: val,
        question: []
      };
      this.info.push(obj_r);
      this.info.push(obj_l);
      this.$nextTick(() => {
        var contentHeight = document.getElementById("content");
        contentHeight.scrollTop = contentHeight.scrollHeight;
      });
    },
    // 点击机器人的单个问题
    clickRobot(val, id) {
      this.sentMsgById(val, id);
    },
    // 结束语
    endMsg() {
      let happyEnding = {
        type: "leftinfo",
        time: this.getTodayTime(),
        content: "您已长时间未回复了哦",
        question: []
      };
      this.info.push(happyEnding);
      this.$nextTick(() => {
        var contentHeight = document.getElementById("content");
        contentHeight.scrollTop = contentHeight.scrollHeight;
      });
    },
    getTodayTime() {
      // 获取当前时间
      var day = new Date();
      let seconds = day.getSeconds();
      if (seconds < 10) {
        seconds = "0" + seconds;
      } else {
        seconds = seconds;
      }
      let minutes = day.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      } else {
        minutes = minutes;
      }
      let time =
        day.getFullYear() +
        "-" +
        (day.getMonth() + 1) +
        "-" +
        day.getDate() +
        " " +
        day.getHours() +
        ":" +
        minutes +
        ":" +
        seconds;
      return time;
    }
  },
  mounted() {
    const emitter = inject("emitter"); // Inject `emitter`
    emitter.on("chat", value => {
      // 监听事件
      this.ishow = value;
    });
  },
  props: {}
};
</script>
<style lang="scss" scoped>
.containerss {
  position: fixed;
  width:50vw;
  left:50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  
}
.main {
  width: 100%;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 15%);
  background: linear-gradient(
    180deg,
    rgba(149, 179, 212, 1) 0%,
    rgba(74, 131, 194, 1) 100%
  );
  overflow: hidden;
  
  .box {
    width: 100%;
    background-color: #fafafa;
    position: relative;
    padding: 1.25rem;

    #content {
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      font-size: 14px;
      width: 100%;
      padding: 0 5px;

      .circle {
        display: inline-block;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: #e9e7ef;
      }

      .con_text {
        color: #333;
        margin-bottom: 5px;
        display: flex;
        justify-content: flex-end;
      }

      .con_que {
        color: #1c88ff;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
      }

      .info_r {
        position: relative;

        .circle_r {
          position: absolute;
          left: 0%;
        }

        .pic_r {
          width: 17px;
          height: 17px;
          margin: 8px;
        }

        .con_r {
          display: inline-block;
          /* max-width: 253px; */
          width: 55%;
          min-height: 55px;
          /* min-height: 20px; */
          background-color: #e2e2e2;
          border-radius: 6px;
          padding: 10px;
          margin-left: 40px;
        }

        .time_r {
          margin-left: 45px;
          color: #999999;
          font-size: 12px;
        }
      }

      .info_l {
        text-align: right;
        // margin-right: 20px;
        color: #ffffff;
        color: #3163c5;
        margin-top: 10px;

        // .circle_l {
        //   // vertical-align: -10px;
        // }
        .pic_l {
          width: 13px;
          height: 17px;
          margin: 8px 10px;
        }

        .time_l {
          margin-right: 45px;
          color: #999999;
          font-size: 12px;
          margin-top: 5px;
        }

        .con_l {
          white-space: normal;
          word-break: break-all;
          display: inline-block;
          width: 220px;
          min-height: 51px;
          background-color: #3163c5;
          border-radius: 6px;
          padding: 10px;
          text-align: left;
          color: #fff;
          margin-right: 5px;
        }
      }

      #question {
        cursor: pointer;
      }
    }
  }
}

.setproblem {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: relative;
  padding: 12px 12px 0 12px;
}

.setproblem textarea {
  color: #999999;
  padding: 10px;
  box-sizing: border-box;
}

.setproblem button {
  width: 80px;
  height: 34px;
  background: #c08c58;
  opacity: 1;
  border-radius: 4px;
  font-size: 10px;
  color: #ffffff;
  position: absolute;
  right: 20px;
  bottom: 8px;
  cursor: pointer;
  border: none;
}

.czkj-item-title {
  line-height: 25px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.czkj-item-question {
  cursor: pointer;
  display: block;
  padding: 8px;
  position: relative;
  border-bottom: 1px dashed #ccc;
  line-height: 20px;
  min-height: 20px;
  overflow: hidden;
}

.czkj-question-msg {
  float: left;
  font-size: 14px;
  color: #3163c5;
}


.Intelligent .head {
  width: 100%;
  height: 42px;
  background-color: #c08c58;
  padding:0 0 0 12px;
  display: flex;
  justify-content: space-between;
}

.Intelligent .head .head-service {
  display:flex;
  align-items:center;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  .inputing{
    font-size:12px;
    padding-left:12px;
  }
}

.Intelligent .head .head-button {
  display: flex;
  justify-content: flex-end;
  align-items:center;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.Intelligent .box {
  width: 100%;
  height: 70vh;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.Intelligent .box .chat {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Intelligent .box .chat .chat-frame {
  width: 100%;
  flex: 1;
  overflow-y: auto;
  // background-color: #f6e9dd;
  background:#f5f5f5;
  padding: 12px 6px;
}

.Intelligent .box .chat .chat-input {
  width: 100%;
  height: 150px;
  background-color: #fff;
}

.Intelligent .box .chat .chat-introduce {
  width: 100%;
  height: 30px;
  background-color: #fff;
  text-align: center;
  line-height: 30px;
  color: #a4a5a6;
}

.Intelligent .box .message {
  width: 250px;
  height: 100%;
  background-color: #f5f5f5;
  padding: 24px;
}

.Intelligent .box .message .portrait {
  width: 100%;
  height: 60px;
  margin-bottom: 12px;

  img {
    width: 100%;
  }
}

.Intelligent .box .message {
  .phone,
  .site,
  .url,
  .WeChat {
    display:flex;
    align-items:center;
    height:28px;
    position: relative;
    font-size:12px;
    .el-icon {
      font-size:28px;
    }
  }
}


.Intelligent .box .message .lfx {
  margin: 12px auto;
  width: 200px;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
  }
}
.setproblems {
  display: flex;
  justify-content: center;
  align-items: center;
}
.shouzhi {
  height: 100%;
}

</style>
