import request from '../util/request';

// 登录
export const login = (data: any) => {
    return request({
        url: '/auth/custLogin',
        method: 'POST',
        data
    })
}

// 注册
export const register = (data: any) => {
    return request({
        url: '/auth/custRegister',
        method: 'POST',
        data
    })
}

//校验注册
export const checkCust = (data: any) => {
    return request({
        url: '/auth/checkCust',
        method: 'POST',
        data
    })
}


//退出登录
export const loginOut = (data: any) => {
    return request({
        url: '/auth/custLogout',
        method: 'DELETE',
        data
    })
}

//获取验证码
export const yanzheng = (params: any) => {
    return request({
        url: '/rent/sms/send/'+params.mobile,
        method: 'get',
        params
    })
}


//
export const custPhoneLogin = (data: any) => {
    return request({
        url: '/auth/custPhoneLogin',
        method: 'POST',
        data
    })
}


//需要帮助
export const help = (data: any) => {
    return request({
        url: '/rent/help',
        method: 'POST',
        data
    })
}

//忘记密码
export const updateCustPasswordByCode = (data: any) => {
    return request({
        url: '/rent/cust/updateCustPasswordByCode',
        method: 'PUT',
        data
    })
}


//验证码验证手机号（修改手机号）
export const checkCustPhonenumber = (data: any) => {  
    return request({
        url: '/rent/cust/checkCustPhonenumber',
        method: 'PUT',
        data
    })
}

//新登录验证码
export const getCode_login = (data: any) => {
    return request({
        url: '/auth/getCode',
        method: 'POST',
        data
    })
}

//新登录输入验证码
export const custDoubleLog = (data: any) => {
    return request({
        url: '/auth/custDoubleLog',
        method: 'POST',
        data
    })
}
