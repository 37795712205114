import request from '../util/request';

//竞租
export const BiCom = (params: any) => {
    return request({
        url: '/rent/bid/getPageList',
        method: 'get',
        params
    })
};

//查询标的及其公告图片文件
export const NotIce = (bidIds: any) => {
    return request({
        url: '/rent/bid/getFlowBidDetailedByBidIds/' + bidIds,
        method: 'get',
    })
};

//新增或修改标的报名 包含相关文件
export const entry = (data: any) => {
    return request({
        url: '/rent/entry',
        method: 'post',
        //requestType: true,
        data
    })
};


//根据客户id获取客户信息
export const getCustByCustId = () => {
    return request({
        url: '/rent/cust/getCustByCustId',
        method: 'get',
    })
};


//
//筛选条件列表
export const queryDict = () => {
    return request({
        url: '/system/dict/data/bidQueryDict',
        method: 'get',
    })
};


//公告
export const getPageNewsIds = (params: any) => {
    return request({
        url: '/rent/notice/getPageList',
        method: 'get',
        params
    })
};


//新版多选公告列表
export const getnoticePageList = (data: any) => {
    return request({
        url: '/rent/notice/getNonLoginPageList',
        method: 'post',
        data
    })
};


//公告详情
export const getNoticeId = (data: any) => {
    return request({
        url: '/rent/query/getNoticeById/'+data.id,
        method: 'get',
    })
};

//商机无限详情
export const getNoXQid = (params:any) => {
    return request({
        url: '/rent/article/select',
        method: 'get',
        params

    })
};


//获取出价是否中标状态
export const updateBiddingResultByEntryIds = (data:any) => {
    return request({
        url: '/rent/entry/updateBiddingResultByEntryIds/'+data.entryIds,
        method: 'put',
        data

    })
};

//增加浏览量
export const updateBidViews = (data:any) => {
    return request({
        url: '/rent/bid/updateBidViews/'+data.bidIds,
        method: 'put',
        data

    })
};

//获取标的个人状态
export const getBidInfoByBidIds = (params:any) => {
    return request({
        url: '/rent/bid/getBidInfoByBidIds/'+params.bidIds,
        method: 'get',
        params

    })
};


//获取资质模版
export const getCustAuditrecordMouldList = (params:any) => {
    return request({
        url: '/rent/sysAttachment/getCustAuditrecordMouldList',
        method: 'get',
        params
    })
};

//判断token是否失效
export const getTokenStatus = (params:any) => {
    return request({
        url: '/rent/query/getTokenStatus',
        method: 'get',
        params
    })
};

//分页查询标的
export const getNonLoginPageList = (data:any) => {
    return request({
        url: '/rent/bid/getNonLoginPageList',
        method: 'post',
        data
    })
};
//分页查询标的
export const checkStatusBidding = (data:any) => {
    return request({
        url: '/rent/bid/checkBidding',
        method: 'put',
        data
    })
};

//5秒刷新一次竞价大厅价格
export const getPriceChangeList = (data:any) => {
    return request({
        url: '/rent/bid/getPriceChangeList',
        method: 'post',
        data
    })
};


//竞价大厅列表
export const getBiddingRoomPageList = (params: any) => {
    return request({
        url: '/rent/bid/getBiddingRoomPageList',
        method: 'get',
        params
    })
};

//特殊标的
export const getEntryVoByBidIds = (params: any) => {
    return request({
        url: '/rent/entry/getEntryVoByBidIds/'+params.bidIds,
        method: 'get',

    })
};

//重新报名
export const againEntry = (data:any) => {
    return request({
        url: '/rent/entry/againEntry',
        method: 'post',
        data
    })
};

//身份认证
export const authQuery = (data: any) => {
    return request({
        url: '/other/sign/authQuery',
        method: 'post',
        data
    })
};

//获取模板下载地址
export const getAttachmentUrl = (params: any) => {
    return request({
        url: '/rent/sysAttachment/getAttachmentUrl',
        method: 'get',
        params
    })
};

//获取资质认证状态
export const getStatus = (params: any) => {
    return request({
        url: '/rent/custAuditrecord/getStatus',
        method: 'get',
        params
    })
};

//获取简介
export const getCompanyProfile = (params: any) => {
    return request({
        url: '/rent/companyProfile/getCompanyProfile',
        method: 'get',
        params
    })
};
