// 这里是封装好的aioxs
import request from "../util/request";

//首页各版块合集
export const homeList = () => {
  return request({
    url: `/rent/query/info`,
    method: "get",
  });
};

//新闻列表
export const getNewsList = (params: any) => {
  return request({
    url: `/rent/article/newList`,
    method: "get",
    params,
  });
};

//新闻详情
export const getNewsDetail = (newsIds: any) => {
  return request({
    url: "/rent/query/getNewsByNewsIds/" + newsIds,
    method: "get",
  });
};

// 获取商机无限列表
export const getAdvertisementsList = (data: {
  // pageNum: number,
  // pageSize: number,
}) => {
  return request({
    url: "/rent/article",
    method: "get",
    params: data,
  });
};
//新闻搜索详情
export const getArticle = (params: any) => {
  return request({
    url: "/rent/article",
    method: "get",
    params,
  });
};

//日历详情数据
export const getCalDetailedList = (params: any) => {
  return request({
    url: "/rent/query/getCalDetailedList",
    method: "get",
    params,
  });
};

//广告位
export const showAdvertising = (params: any) => {
  return request({
    url: "/rent/sysAttachment/showAdvertising",
    method: "get",
    params,
  });
};

// // 登录
// export const login = (data: any) => {
//     return request({
//         url: 'login',
//         method: 'POST',
//         data
//     })
// }

// //竞租
// export const jingzu = (data: any) => {
//     return request({
//         url: 'api/jingzu',
//         method: 'get',
//         data
//     })
// }
// //竞价
// export const jingjia = (data: any) => {
//     return request({
//         url: 'api/jingjia',
//         method: 'get',
//         data
//     })
// }
// //公告公示
// export const gonggao = (data: any) => {
//     return request({
//         url: 'api/gonggao',
//         method: 'get',
//         data
//     })
// }
// //竞价时事
// export const jingjiashishi = (data: any) => {
//     return request({
//         url: 'api/jingjiashishi',
//         method: 'get',
//         data
//     })
// }
