/*
 * @Date: 2022-08-18 17:56:48
 * @LastEditors: xiao
 * @LastEditTime: 2022-08-30 10:27:04
 * @FilePath: \rongyizu\src\util\request.ts
 */
// 首先先引入aixos
import axios from "axios";
// element引入文件
import { ElMessageBox, ElMessage } from "element-plus";
import { ElLoading } from "element-plus";
import qs from "qs";
//引入获取token方法
import { authUtils } from "./auth";
//请求返回后的状态处理
import { showMessage } from "./status";

import UserData from "@/store/user";
import router from "@/router";
import { loginOut } from "@/api/login";
import { close, start } from "@/common/js/np";
// 基准地址
const env = {
  local: {
    baseURL: "https://2315312.com",
  },
  test: {
    baseURL: "https://test.com",
  },
  prod: {
    baseURL: "xxx",
  },
  http: {
    baseURL: "/dev-api",
  },
};

//\const UserDataStore = UserData();

declare module "axios" {
  interface AxiosInstance {
    (config: any): Promise<any>;
  }
}

const config = {
  baseURL: env.http.baseURL,
  headers: {
    //
  },
};
let loadingInstance: any = null;
// 创建一个axios 实例
const api = axios.create({
  baseURL: env.http.baseURL, // 基准地址
  // timeout: 50000, // 超时时间
});
// 请求拦截
api.interceptors.request.use(
  (config: any) => {
    //return config;     // 请求拦截里面的需求
    // 请求头携带token
    //start()

    if (!config.loading) {
      // loadingInstance = ElLoading.service({
      //   lock: true,
      //   text: "加载中...",
      //   background: "transparent",
      // });
    }
    if (authUtils.getToken()) {
      if (config && config?.headers) {
        config.headers["Authorization"] = "Bearer " + authUtils.getToken();
      }
    }

    if (config["requestType"]) {
      if (config && config?.headers) {
        // alert(456)
        config.headers["Content-Type"] = "multipart/form-data";
      }
      config.data = qs.stringify(config.data);
    }
		
    // 验证请求状态码
    config.validateStatus = (status: any) => {
      if (status > 200) {
        console.warn(showMessage(status));
      }
      return status >= 200 && status < 400;
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// 响应拦截
/**
响应拦截也是一样，最基本的可以把返回出来的一些数据做简单处理，
比如把请求回来的提示信息解构出来，给予提示，这样就不用在每次请求完成后单独去写
这里用的是element-ui 里面的提示
**/
api.interceptors.response.use(
  (res: any) => {
    const { data } = res;
    // const { data: result, meta: { msg, status } } = data;
    // const { data: result } = data;
    if (data.data || data.code < 400) {
    } else if (data.code == 401) {
      // UserDataStore.SET_USER({});
      // UserDataStore.SET_Login(false);
      if(UserData().$state.isLogin){
        loginOut({}).then((res: any) => {
          if (res.code === 200) {
            UserData().SET_USER({});
            UserData().SET_Login(false);
            if (data.msg != "令牌不能为空") {
              ElMessage.warning(data.msg);
              router.push("/");
            } else {
              ElMessageBox.confirm("您还未登录，是否先前往登录~", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
              }).then(() => {
                router.push("/login?name=login");
              });
            }
          }
        });
      }else{
        UserData().SET_USER({});
        UserData().SET_Login(false);
        //ElMessage.warning(data.msg);
        router.push("/");
        //
      }
    } else if (data.code == 402) {
      // UserDataStore.SET_USER({});
      // UserDataStore.SET_Login(false);
      loginOut({}).then((res: any) => {
        if (res.code === 200) {
          UserData().SET_USER({});
          UserData().SET_Login(false);
          //ElMessage.warning(data.msg);
          router.push("/");
          //
        }
      });
    } else {
      if(data && data.msg) {
        ElMessage({
          message: data.msg,
          type: "warning",
          offset: 200,
          grouping: true,
        });
      }

    }
    setTimeout(() => {
      //loadingInstance.close();
      //close()
    }, 500);
    return data;
  },
  (error) => {
    Promise.reject(error);
  }
);
// 最后导出
export default api;
