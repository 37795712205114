import { defineStore } from 'pinia' //引入
const useStore:any = defineStore('common', {
  persist: true,
  state: () => {
    return {
      isLogin: false,
      user: {}
    }
  },
  getters: {
    getUser: (state) => state.user,
  },
  actions: {
    SET_USER(data: any) {
      this.user = data
    },
    SET_Login(data: boolean) {
      this.isLogin = data
    }
  }
})
export default useStore //导出