export function toFixedNum(num:any, decimal:any = 2){
    if(isNaN(num)){
      return 0
    }
    num = num - 0
    const p1 = Math.pow(10, decimal + 1)
    const p2 = Math.pow(10, decimal)
    return (Math.round(num * p1 / 10) / p2).toFixed(decimal)
  }

export function parseTime(time:any, pattern?:any) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj:any = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result:any, key:any) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function isCurrentTimeInRange(startTimeStr: string, endTimeStr: string) {
  let startTime = new Date(startTimeStr);
  let endTime = new Date(endTimeStr);
  const now = new Date();

  // 确保开始时间在结束时间之前（如果不是，则交换它们）
  if (startTime > endTime) {
    [startTime, endTime] = [endTime, startTime];
  }

  return now >= startTime && now <= endTime;
}


/**
 * @description:  打印模板
 * @param {sting} domId 当前打印模版的 id
 * @param {boolean} noMargin 是否去除边距
 * @param {sting} direction 打印方向 landscape;//横向 portrait;//纵向
 * @param {sting} margin 自定义打印边距
 */
export function printTemplateByClassName(domId: string, noMargin: boolean, direction = '', margin = ''): void {
  let iframe = null
  iframe = document.createElement('iframe') as HTMLIFrameElement
  document.body.appendChild(iframe)
  iframe.style.display = "none";
  iframe.setAttribute('class', 'bids-area__iframe')
  iframe.setAttribute('allowTransparency', String(true))
  // const ele = document.getElementsByTagName('iframe')
    // if (!ele.length) {
    //   iframe = document.createElement('IFRAME') as HTMLIFrameElement
    //   document.body.appendChild(iframe)
    // } else {
    //   iframe = ele[0]
    // }
  const contentWindow =  iframe!.contentWindow
  const doc =  contentWindow!.document
  const printContent = document.getElementById(domId) as HTMLElement

  // 3-3
  // 新建link标签
  const linkTag = document.createElement("link")
  // 设置lin标签属性
  linkTag.setAttribute("rel", "stylesheet")
  // 设置style标签内容
  linkTag.href = "/static/css/print.css";
  // 3-5
  // 数据写入
  doc.write("<div>" + printContent.innerHTML + "</div>");
  doc.close();
  //3-6
  const head = doc.head
  // if (noMargin) {
  //   const styleEle = doc.createElement('style')
  //   if (direction) {
  //     styleEle.innerHTML = `@page { margin: 0; padding: 0;size: ${direction}}`
  //   } else {
  //     styleEle.innerHTML = `@page { margin: 10; padding: 0;  }`
  //   }
  //   head.appendChild(styleEle)
  // } else {
  //   const styleEle = doc.createElement('style')
  //   if (direction) {
  //   // ; mso-header:none; mso-footer:none;
  //     styleEle.innerHTML = `@page { margin: ${margin}; padding: 0;size: ${direction}}`
  //   } else {
  //     // ;mso-header:none; mso-footer:none;
  //     styleEle.innerHTML = `@page { margin: ${margin}; padding: 0; }`
  //   }
  //   head.appendChild(styleEle)
  //   // addExternalStylesheet('/css/print.css', parent)
  // }
  // 3-7
  // 获取iframe html文件head(必须在写入数据之后进行，写入数据时，会将head中的数据置空)
  // link标签添加进iframe html文件的head里
  head.appendChild(linkTag)
  //去除边距
  // 缺少这个样式还是不能生效
  setTimeout(() => {
    contentWindow!.focus()
    contentWindow!.print()
  }, 150)

  // document.body.removeChild(iframe);//删除iframe

}

/**
 * @description:  打印模板
 * @param {sting} domId 当前打印模版的 id
 * @param {boolean} noMargin 是否去除边距
 * @param {sting} direction 打印方向 landscape;//横向 portrait;//纵向
 * @param {sting} margin 自定义打印边距
 */
export function printTemplateByDomId(domId: string, noMargin: boolean, direction = '', margin: string) {

  let iframe = null
  iframe = document.createElement('IFRAME') as HTMLIFrameElement
  document.body.appendChild(iframe)

  iframe.setAttribute('class', 'bids-area__iframe')
  const contentWindow =  iframe!.contentWindow
  const doc = contentWindow!.document
  const printAreaDom = document.getElementById(domId) as HTMLElement
  doc.write(printAreaDom.innerHTML)
  doc.close()
  debugger
  //去除边距
  if (noMargin) {
    const parent = doc.getElementsByTagName('html')[0]
    const styleEle = doc.createElement('style')
    if (direction) {
      styleEle.innerHTML = `@page { margin: 0; padding: 5px;size: ${direction}}`
    } else {
      styleEle.innerHTML = '@page { margin: 0; padding: 5px;}'
    }
    parent.appendChild(styleEle)
  } else {
    const parent = doc.getElementsByTagName('html')[0]
    const styleEle = doc.createElement('style')
    if (direction) {
      styleEle.innerHTML = `@page { margin: ${margin}; padding: 0;size: ${direction}}`
    } else {
      styleEle.innerHTML = `@page { margin: ${margin}; padding: 0;};  mso-header:none; mso-footer:none;`
    }
    parent.appendChild(styleEle)
  }
  contentWindow!.focus()
  contentWindow!.print()
}

export function addExternalStylesheet(href: string, parent: HTMLElement) {
  // 创建 link 元素
  const linkElement = document.createElement('link');
  // 设置 link 标签属性
  linkElement.setAttribute('rel', 'stylesheet');
  linkElement.setAttribute('type', 'text/css');
  linkElement.setAttribute('href', href);

  // 插入到文档头部
  // const head = document.getElementsByTagName('head')[0];
  parent.appendChild(linkElement);
}
export function getUrlParams(url: string) {
  // 创建URL对象
  const urlObj = new URL(url);
  // 获取查询字符串参数集合
  const searchParams = new URLSearchParams(urlObj.search);

  // 转换为对象形式
  const params = {};
  for (const [key, value] of searchParams.entries()) {
    // @ts-ignore
    params[key] = value;
  }
  return params;
}

export function getPathAfterLastSlashBeforeQuery(url: string) {
  // 使用URL构造函数解析URL
  const urlObj = new URL(url);
  // 获取pathname（即URL中的路径部分）
  const pathname = urlObj.pathname;
  // 找到最后一个'/'的位置
  const lastSlashIndex = pathname.lastIndexOf('/');
  // 如果有'/'，则提取'/’后的部分；否则返回空字符串，表示没有路径部分
  const pathAfterLastSlash = lastSlashIndex !== -1 ? pathname.substring(lastSlashIndex + 1) : '';
  return pathAfterLastSlash;
}

/**
 * 根据时间字段排序
 * @param data
 */
export function sortByCreateTime(data: any) {
  const sortDate = data.sort((a: any, b: any) => {
    // 将字符串时间转换为Date对象进行比较
    const dateA = new Date(a.createTime);
    const dateB = new Date(b.createTime);

    // 返回值小于0，则a排在b之前，即升序排序
    // @ts-ignore
    return dateB - dateA;
  });
  return sortDate;
}
export function generateUniqueId() {
  const now = Date.now(); // 获取当前时间的毫秒时间戳
  const randomPart = Math.floor(Math.random() * 1000); // 生成0到999之间的随机数
  return now.toString() + randomPart.toString().padStart(3, '0'); // 拼接时间戳和随机数，确保长度一致
}


/**
 * 下载文件
 * @param url
 * @param fileName
 * @returns {Promise<void>}
 */
export async function downloadFile(url: string, fileName?: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  // 创建一个隐藏的可下载链接元素
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', fileName || '模板文件.pdf'); // 设置下载的文件名
  document.body.appendChild(link);
  link.click();
  // @ts-ignore
  link.parentNode.removeChild(link);
}