// 这里是封装好的aioxs
import request from '../util/request'

//新增收藏
export const coolectid = (data: any) => {
    return request({
        url: '/rent/collect/'+data.bidId,
        method: 'POST',
        data
    })
};
//删除收藏   小富写的借口，列表那里用
export const CancelCollection = (data: any) => {
    return request({
        url: '/rent/collect',
        method: 'DELETE',
        data
    })
};
//删除收藏   康总写的借口，标的详情用
export const CancelCollection2 = (data: any) => {
    return request({
        url: '/rent/collect/deleteByBidIds/'+data.bidIds,
        method: 'DELETE',
        data
    })
};
//我的资质授权列表
export const getAuditrecordVoPageListByLoginCustId = (params: any) => {
    return request({
        url: `/rent/custAuditrecord/getAuditrecordVoPageListByLoginCustId`,
        method: 'get',
        params
    })
}
//查看我的资质
export const getAuditrecordVoByAuditrecordIds = (params: any) => {
    return request({
        url: `/rent/custAuditrecord/getAuditrecordVoByAuditrecordIds/`+params.auditrecord_ids,
        method: 'get',
        params
       
    })
}
//新增修改我的资质（旧接口）
export const custAuditrecord_push = (data: any) => {
    return request({
        url: `/rent/custAuditrecord/`,
        method: 'post',
        data
    })
}

//保存基础信息
export const addBase = (data: any) => {
    return request({
        url: `/rent/custAuditrecord/addBase`,
        method: 'post',
        data
    })
}

//CA身份认证
export const addCA = (data: any) => {
    return request({
        url: `/rent/custAuditrecord/addCA`,
        method: 'post',
        data
    })
}

//身份材料提交
export const addFile = (data: any) => {
    return request({
        url: `/rent/custAuditrecord/addFile`,
        method: 'post',
        data
    })
}

//获取消息
export const updateReadedStatus = (data: any) => {
    return request({
        url: '/rent/message/updateReadedStatus/'+data.messageIds,
        method: 'put',
        data
    })
}
//查询标的相关公告
export const getNoticeListByBidIds = (data: any) => {
    return request({
        url: '/rent/notice/getNoticeListByBidIds/'+data.bidIds,
        method: 'get',
        data
    })
}
//交易日历
export const getCalList = (data: any) => {
    return request({
        url: `/rent/query/getCalList`,
        method: 'post',
        data
    })
}
//获取标的个人状态
export const getBidInfoByBidIds = (params:any) => {
    return request({
        url: '/rent/bid/getBidInfoByBidIds/'+params.bidIds,
        method: 'get',
        params
      
    })
};
//下载中心附件信息列表
export const sysAttachment = (params:any) => {
    return request({
        url: `/rent/sysAttachment/list`,
        method: 'get',
        params
      
    })
};
//获取帮助
//需要帮助
export const help = (data: any) => {
    return request({
        url: '/rent/help',
        method: 'POST',
        data
    })
}

//获取第一条资质认证
export const getFirstHistoryAuditrecordVo = (params:any) => {
    return request({
        url: '/rent/custAuditrecord/getFirstHistoryAuditrecordVo',
        method: 'get',
        params
    })
};

//一键已读
export const updateAllCustReadedStatust  = (data:any) => {
    return request({
        url: '/rent/message/updateAllCustReadedStatus',
        method: 'put',
        data
    })
}

export const getMsg = () => {
	return request({
		url: '/rent/archiving/webSocket',
		method: 'get',
		loading: true
	})
}

export const getBidingBidVoList = () => {
	return request({
		url: '/rent/entry/getBidingBidVoList',
		method: 'get',
		loading: true
	})
}

