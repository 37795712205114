const authUtils = {
    getBaseURL() {
        // return "https://www.abroadpass.com/web"
        return "http://nero.free.idcfengye.com"
    },
    getToken() {
        const indexData = localStorage.getItem("common")
        const d = JSON.parse(JSON.parse(JSON.stringify(indexData)))
        if (d && d.isLogin) {
            return d.user.access_token
        } else {
            return ''

        }
    }
}
export { authUtils }
